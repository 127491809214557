import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="h1" components={components}>{`Events`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h2" components={components}>{`July 11th, Self Sovereign Identity and Web 3.0`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "/ssi-tickets"
        }}><span className="gatsby-resp-image-wrapper" style={{
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1080px"
          }}>
    <span className="gatsby-resp-image-background-image" style={{
              "paddingBottom": "142.6%",
              "position": "relative",
              "bottom": "0px",
              "left": "0px",
              "backgroundSize": "cover",
              "display": "block"
            }}></span>
    <img className="gatsby-resp-image-image" style={{
              "width": "100%",
              "height": "100%",
              "margin": "0px",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0px",
              "left": "0px",
              "boxShadow": "inset 0px 0px 0px 400px white"
            }} alt="TPEventSocialPost 0307 V03" title="" src="/static/5c1fae8bc67b9acad434302ed48ce2cd/54f40/TPEventSocialPost_0307_V03.jpg" srcSet="/static/5c1fae8bc67b9acad434302ed48ce2cd/092e8/TPEventSocialPost_0307_V03.jpg 334w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/d6e26/TPEventSocialPost_0307_V03.jpg 668w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/54f40/TPEventSocialPost_0307_V03.jpg 1336w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/94198/TPEventSocialPost_0307_V03.jpg 2004w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/4a6ed/TPEventSocialPost_0307_V03.jpg 2672w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/c9854/TPEventSocialPost_0307_V03.jpg 4008w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/82feb/TPEventSocialPost_0307_V03.jpg 4500w" sizes="(max-width: 1080px) 100vw, 1080px" />
  </span></MDXTag></MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h2" components={components}>{`July 5th through 6th, #coophack hackathon`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "/hack-tickets"
        }}><span className="gatsby-resp-image-wrapper" style={{
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1336px"
          }}>
    <span className="gatsby-resp-image-background-image" style={{
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0px",
              "left": "0px",
              "backgroundSize": "cover",
              "display": "block"
            }}></span>
    <img className="gatsby-resp-image-image" style={{
              "width": "100%",
              "height": "100%",
              "margin": "0px",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0px",
              "left": "0px",
              "boxShadow": "inset 0px 0px 0px 400px white"
            }} alt="coophack 2019 8 64 70" title="" src="/static/3c6f7161ca5a5df56ff26b632b93c702/514d5/coophack-2019-8-64-70.png" srcSet="/static/3c6f7161ca5a5df56ff26b632b93c702/fe72e/coophack-2019-8-64-70.png 334w,
/static/3c6f7161ca5a5df56ff26b632b93c702/70b80/coophack-2019-8-64-70.png 668w,
/static/3c6f7161ca5a5df56ff26b632b93c702/514d5/coophack-2019-8-64-70.png 1336w,
/static/3c6f7161ca5a5df56ff26b632b93c702/dd69c/coophack-2019-8-64-70.png 2004w,
/static/3c6f7161ca5a5df56ff26b632b93c702/a8e30/coophack-2019-8-64-70.png 2160w" sizes="(max-width: 1336px) 100vw, 1336px" />
  </span></MDXTag></MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    